/** @jsx jsx */
import { jsx } from "theme-ui"
import { Grid } from "@theme-ui/components"
import { graphql, useStaticQuery } from "gatsby"
import { navigate } from "@reach/router"
import SEO from "@components/seo"
import CTAHeadingBlock from "@components/CTAHeadingBlock"
import Layout from "@components/Layouts"
import Container from "@components/Container"
import CalloutTextBlocks from "@components/CalloutTextBlocks"
import ArticleCard from "@components/ArticleCard"
import TwoColumnSection, {
  Block as ColumnBlock,
  ImageBlock as ColumnImageBlock,
} from "@components/TwoColumnSection"
import Section from "@components/Section"
import ImageTextBlocksList from "@components/ImageTextBlocksList"
import Link from "@components/Link"
import { rem } from "@src/theme"
import PartnersSection from "@components/PartnersSection"
import { DonateSection } from "@components/ImpactFooter"
import PillList from "@components/PillList"
import { TAG_OPTIONS } from "@content/forms"
import pageContent from "@content/homepage"
import { formatMarkdownToHtml } from "@helpers"
import MarkdownBody from "@modules/MarkdownBody"
import { getSlug } from "@helpers/utils/getSlug"
import ImageGallery from "@components/ImageGallery"
import CallToAction from "@components/CallToAction"
import QuickLinks from "@components/QuickLinks"
import styled from "styled-components"
import BannerCta from "@components/BannerCta"
import PartnerLogos from "@components/PartnerLogos"

export const HomeTemplate = ({ data }) => {
  const { frontmatter } = data.pageData
  const {
    header,
    partnersLogo,
    banner,
    ogimage,
    ogdescription,
    threeColumnTextBlock,
    imageGallery,
    imageTextBlock,
    ctaBlock,
    quickLinks,
    smallCta,
    partnerLogos,
    ogTitle,
  } = frontmatter

  return (
    <Layout>
      <SEO
        title={ogTitle || header?.heading}
        image={ogimage}
        ogdescription={ogdescription}
      />
      {banner && (
        <MarkdownBody
          html={formatMarkdownToHtml(banner)}
          sx={{
            bg: "turquoise",
            color: "darkPurple",
            textAlign: "center",
            py: rem(25),

            "h1,h2,h3,h4,h5": {
              my: 0,
            },
          }}
        />
      )}

      <TwoColumnSection
        col1={
          <div>
            <ColumnBlock
              styles={{
                bg: "purple",
                color: "pink",
              }}
            >
              <CTAHeadingBlock textBorder heading={header.heading} />
            </ColumnBlock>
            <ColumnBlock
              styles={{
                bg: "pink",
                color: "darkPurple",
              }}
            >
              <CTAHeadingBlock
                heading={header.subheading}
                body={header.subtext}
                buttonLabel={header.ctaButton.label}
                buttonLink={header.ctaButton.link}
                smallHeading
              />
            </ColumnBlock>
          </div>
        }
        col2={<ColumnImageBlock image={header.image} />}
      />

      {smallCta && smallCta.heading && (
        <BannerCta
          heading={smallCta.heading}
          text={smallCta.text}
          imageFit={smallCta.imageFit}
          cta={
            smallCta?.ctaButton && {
              url: smallCta?.ctaButton?.link,
              text: smallCta?.ctaButton?.label,
            }
          }
          img={{
            src: smallCta.image,
            alt: smallCta.heading,
          }}
          backgroundImg={smallCta.backgroundImg}
        />
      )}

      {threeColumnTextBlock.map(row => (
        <Container>
          <Section>
            <CalloutTextBlocks blocks={row.columns} heading={row.heading} />
          </Section>
        </Container>
      ))}

      {imageGallery &&
        Object.values(imageGallery).filter(value => value !== "").length >
          0 && <ImageGallery {...imageGallery} />}

      {/* <Section
        sx={{
          bg: "pink",
        }}
      >
        <PillList
          options={TAG_OPTIONS}
          heading="Brush up on your knowledge"
          onPillClick={tag => navigate(`/articles?tag=${tag}`)}
        />
        <Container>
          <Grid gap={rem(32)} columns={[1, 2, 3, 4]}>
            {data.articles && data.articles.edges.map(item => (
              <ArticleCard
                key={item.node.id}
                data={{
                  ...item,
                  slug: getSlug(item),
                  preview: item.node.frontmatter.cardPreview,
                }}
              />
            ))}
          </Grid>
        </Container>
      </Section> */}
      <CallToAction {...ctaBlock} />
      {imageTextBlock &&
        imageTextBlock?.rows?.length &&
        (imageTextBlock.gradientBackground ? (
          <GradientWrapper>
            <ImageTextBlocksList blocks={imageTextBlock.rows} />
          </GradientWrapper>
        ) : (
          <ImageTextBlocksList blocks={imageTextBlock.rows} />
        ))}
      <Section noPb>
        <QuickLinks
          heading={quickLinks.heading}
          quickLinks={quickLinks.links}
        />

        {partnerLogos && <PartnerLogos {...partnerLogos} />}
        <DonateSection />
      </Section>
    </Layout>
  )
}

const Home = () => {
  const data = useStaticQuery(
    graphql`
      query {
        pageData: markdownRemark(
          fileAbsolutePath: { glob: "**/pages/home/index.md" }
        ) {
          frontmatter {
            header {
              ...Header
            }
            threeColumnTextBlock {
              heading
              columns {
                image
                heading
                blockHeading
                text
                ctaButton {
                  label
                  link
                }
              }
            }
            imageTextBlock {
              gradientBackground
              rows {
                image
                heading
                text
                links {
                  label
                  link
                }
              }
            }
            imageGallery {
              image1
              image2
              image3
              image4
              image5
              image6
            }
            ctaBlock {
              heading
              subheading
              text
              ctaButton {
                label
                link
              }
            }
            quickLinks {
              heading
              links {
                image
                heading
                text
                link
              }
            }
            smallCta {
              image
              imageFit
              heading
              text
              backgroundImg
              ctaButton {
                label
                link
              }
            }
            partnerLogos {
              heading
              text
              ctaButton {
                label
                link
              }
              partners {
                image
              }
              supporters {
                image
              }
            }
            banner
            ogTitle
            ogimage
            ogdescription
          }
        }
      }
    `
  )

  return <HomeTemplate data={data} />
}

const GradientWrapper = styled.div`
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(221, 243, 255, 0) 0.01%,
      rgba(14, 125, 185, 0.5) 100%
    ),
    #ddf3ff;
`

export default Home
